<template>
  <v-container class="window" style="margin-left:11%; margin-right:11%; width: 78%;">
    <v-data-table :headers="headers" :items="userData" sort-by="calories" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Menu</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px" scrollable>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="success" dark class="mb-2" v-bind="attrs" v-on="on" @click="resetValidation2">
                Tambah Menu
              </v-btn>
            </template>
            <v-card>
              <v-toolbar color="primary" dark>{{ formTitle }}</v-toolbar>
              <v-card-text>
                <v-container>
                  <v-alert dense text type="warning" v-if="validation.menu_name">
                    {{ validation.menu_name[0] }}
                  </v-alert>
                  <v-alert dense text type="warning" v-if="validation.menu_desc">
                    {{ validation.menu_desc[0] }}
                  </v-alert>
                  <v-alert dense text type="warning" v-if="validation.role_id">
                    {{ validation.role_id[0] }}
                  </v-alert>
                  <v-alert dense text type="warning" v-if="validation.seq">
                    {{ validation.seq[0] }}
                  </v-alert>
                  <v-alert dense text type="warning" v-if="validation.active_flag">
                    {{ validation.active_flag[0] }}
                  </v-alert>
                  <v-alert dense text type="warning" v-if="validation.is_detail">
                    {{ validation.is_detail[0] }}
                  </v-alert>
                  <v-alert dense text type="warning" v-if="validation.attribute3">
                    {{ validation.attribute3[0] }}
                  </v-alert>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.menu_name" label="Menu Name"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.menu_desc" label="Menu Desc"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete ref="role_id" v-model="editedItem.role_id" :items="role_id" item-text="role_name"
                        item-value="id" label="Role" placeholder="Select..." required></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.seq" label="Seq" type="number"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <p>Active Flag</p>
                      <v-radio-group v-model="editedItem.active_flag" row>
                        <v-radio label="Y" value="Y"></v-radio>
                        <v-radio label="N" value="N"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12">
                      <p>Is Detail</p>
                      <v-radio-group v-model="editedItem.is_detail" row>
                        <v-radio label="Y" value="Y"></v-radio>
                        <v-radio label="N" value="N"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-divider></v-divider>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        {{ formatDate(item.created_at) }}
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        {{ formatDate(item.updated_at) }}
      </template>
      <!-- <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn>
    </template> -->
    </v-data-table>
  </v-container>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'Menu Name', value: 'menu_name' },
      { text: 'Menu Desc', value: 'menu_desc' },
      // { text: 'Role Id', value: 'role_id' },
      // { text: 'Seq', value: 'seq' },
      // { text: 'Active_flag', value: 'active_flag' },
      // { text: 'Is Detail', value: 'is_detail' },
      // { text: 'Created By', value: 'created_by' },
      // { text: 'Last Update By', value: 'last_update_by' },
      // { text: 'Created At', value: 'created_at' },
      // { text: 'Updated At', value: 'updated_at' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
    ],
    editedIndex: -1,
    editedItem: {},
    userData: [],
    userUpdate: {},
    defaultItem: {},
    resetValidation: [],
    role_id: [],
    validation: []
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Menu' : 'Edit Menu'
    },
    ...mapGetters({
      user: 'user',
    })
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    let uri = `v1/menus`;
    axios.get(uri).then(response => {
      this.userData = response.data.data;
    });
    let uri3 = `v1/roles`;
    axios.get(uri3).then((response) => {
      this.role_id = response.data.data;
      // console.log(this.role_id)
    });
  },
  methods: {
    editItem(item) {
      this.resetValidation2();
      this.editedIndex = this.userData.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.userData.indexOf(item)
      this.editedItem = Object.assign({}, item)
      // this.dialogDelete = true
      this.$swal.fire({
        title: 'Apakah anda ingin menghapus data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Hapus'
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: 'delete',
            url: 'v1/deletemenus',
            data: {
              id: this.editedItem.menu_id
            },
          })
            .then(() => {
              let uri = `v1/menus`;
              axios.get(uri).then(response => {
                this.userData = response.data.data;
              });
              this.$swal.fire(
                'Sukses!',
                'Data Berhasil dihapus',
                'success'
              )
              this.closeDelete()
            })
            .catch(error => {
              console.log(error.response)
              this.$swal.fire(
                'Gagal!',
                'Data Gagal dihapus',
                'warning'
              )
            })
        }
      })
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      if (this.editedIndex > -1) {
        this.$swal.fire({
          title: 'Apakah anda ingin mengupdate data ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Update'
        }).then((result) => {
          if (result.isConfirmed) {
            axios({
              method: 'post',
              url: 'v1/updatemenus',
              data: this.editedItem
            })
              .then(() => {
                let uri = `v1/menus`;
                axios.get(uri).then(response => {
                  this.userData = response.data.data;
                });
                this.$swal.fire(
                  'Sukses!',
                  'Data berhasil di update!',
                  'success'
                )
                this.close();
              })
              .catch(error => {
                this.validation = error.response.data.data;
                this.$swal.fire(
                  'Gagal!',
                  'Data Gagal diupdate',
                  'warning'
                )
              })
          }
        })
      } else {
        this.$swal.fire({
          title: 'Apakah anda ingin menambahkan data ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Tambah'
        }).then((result) => {
          if (result.isConfirmed) {
            axios({
              method: 'post',
              url: 'v1/createmenus',
              data: {
                dataMenu: this.editedItem,
                id: this.user.id
              }
            })
              .then(() => {
                let uri = `v1/menus`;
                axios.get(uri).then(response => {
                  this.userData = response.data.data;
                });
                this.$swal.fire(
                  'Sukses!',
                  'Data berhasil di simpan!',
                  'success'
                )
                this.close();
              })
              .catch(error => {
                this.validation = error.response.data.data;
                this.$swal.fire(
                  'Gagal!',
                  'Data Gagal disimpan',
                  'warning'
                )
              })
          }
        })
      }
    },
    formatDate(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    resetValidation2() {
      this.validation = [];
      this.validation.splice(0);
    }
  },

}
</script>